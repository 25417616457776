 
 
 .wrapper {
    font-family: 'Manrope', sans-serif;
    margin: 0;
    padding: 1px;
    background: url('https://i.imgur.com/ZagWeU6.jpg') no-repeat center center/cover;
    text-align: center;
    color: #222;
  }
  
  .wrapper .overlay {
    background: rgba(255, 255, 255, 0.6);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .wrapper .container {
    max-width: 780px;
    margin: 80px auto;
    background: rgba(255, 255, 255, 0.9);
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 2;
  }
  
  .wrapper .logo {
    width: 550px;
    max-width: 100%;
    margin-bottom: 10px;
  }
  
  .wrapper h1 {
    font-size: 28px;
    color: #6a00f4;
    text-transform: uppercase;
  }
  
  .wrapper p {
    font-size: 18px;
    color: #444;
    line-height: 1.5;
  }
  
  .wrapper .cta-button {
    display: inline-block;
    padding: 15px 30px;
    background: linear-gradient(90deg, #6a00f4, #ff8800);
    color: white;
    font-size: 20px;
    border-radius: 30px;
    text-decoration: none;
    margin-top: 30px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    transition: transform 0.3s ease-in-out;
    max-width: 110%;

  }
  
  .wrapper .cta-button:hover {
    transform: scale(1.05);
  }
  
  .wrapper .progress-container {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin: 25px 0;
    height: 35px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .wrapper .progress-bar {
    height: 100%;
    background: linear-gradient(90deg, #6a00f4, #ff8800);
    border-radius: 12px;
    transition: width 0.5s ease-in-out;
    position: absolute;
    left: 0;
  }
  
  .wrapper .progress-text {
    font-size: 16px;
    font-weight: bold;
    color: white;
    text-align: center;
    position: absolute;
    width: 100%;
    z-index: 2;
  }
  
  .wrapper .popup-overlay,
  .wrapper .popup,
  .wrapper .success-popup {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 460px;
    max-width: 90%;
    text-align: center;
  }
  
  .wrapper .popup input,select {
    width: 95%;
    padding: 8px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: block;
    height: 38px;
  }
  
  .wrapper .popup button,
  .wrapper .success-popup button {
    width: 95%;
    padding: 9px;
    background: #6a00f4;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    margin-top: 10px;
    height: 38px;
    font-size: 13px;
  }
  
  .wrapper .popup button:hover,
  .wrapper .success-popup button:hover {
    opacity: 0.9;
  }
  
  .wrapper .social-icons {
    margin-top: 15px;
  }
  
  .wrapper .social-icons a {
    margin: 0 10px;
    font-size: 24px;
    color: #6a00f4;
    text-decoration: none;
  }
  
  /* .wrapper .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: black; 
  } */

  .wrapper .close-button {
    position: absolute !important;
    top: -25px !important;
    right: 10px !important;
    border: none !important;
    font-size: 24px !important;
    cursor: pointer !important;
    z-index: 1001 !important; /* Ensure it's above all elements */
    padding: 0px 0px!important;
    }

  
  
  /* React-specific visibility control */
  .wrapper .popup-overlay,
  .wrapper .popup,
  .wrapper .success-popup {
    display: block;
  }
  
  .wrapper .popup-overlay {
    display: block;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .wrapper .popup {
    display: block;
  }
  
  .wrapper .success-popup {
    display: block;
  }
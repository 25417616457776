@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

/* html {
    @apply text-white bg-black;
} */

body {
    background-color: rgb(23 23 23 / var(--tw-bg-opacity));
    font-family: "Manrope", sans-serif;
  }

@layer utilities {

    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none;  
        scrollbar-width: none;  
    }
}
